import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {Link } from "gatsby";
import './Notification.scss';
import moment from "moment";
import ReapitView from "../ReapitView";

const Notification = (props) => {
    const [TobBarShow, setTobBar] = useState(false);

    const TopBarClose = () => {
        setTobBar(!TobBarShow);
    };
    useEffect(() => {
        if(props.openHouse) {
            setTobBar(true)
        }
    }, [])
    useEffect(() => {
        document.body.classList.toggle('notifaction-off', TobBarShow);
    },[TobBarShow]);

    const newTime = new Date(moment().endOf('week').subtract("hours", 8));
    const now = new Date().getTime();
    const distance = newTime - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    return (
        <React.Fragment>
            <div className={TobBarShow? "topbar d-block": "topbar"}>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <div className="topbar-block">
                                <p><strong>Only {days} days remaining of our open house</strong> Reserve your spot for our next open house so you don’t miss out.</p>
                                <ReapitView
                                    crmId={props?.property?.crm_id}
                                    dangerouslySetInnerHTML={{ 
                                        __html: `
                                            <a href="javascript:;" class="btn reapit-viewing-btn" property.id=${props?.property?.crm_id} message.appointment.title="Viewing Requested">
                                                get in touch
                                            </a>
                                        `
                                    }}
                                />
                                <i className="icon-close-modal" onClick={TopBarClose}></i>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Notification;
